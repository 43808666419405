


#evento_title{
  margin-top: 15vh;
  font-size: 1.2vw;
}

.info_necessarie{
  margin-top: 3vh;
  border: 1px solid #BA1852;
  border-radius: 10px;
  padding: 40px;
}

.date_picker_eventi {
  border: 0;
  border-bottom: 1px solid black;
  width: 100%;
  margin-top: -0.8vh;
  font-size: 1.3vw;
}

.descrizione_evento{
  margin-top: 30px;
}

.button_salva_evento {
  text-align: end;
  margin-top: 30px;
}