.contenitore_eventi{
  height: 100%;
}

.foto_eventi{
  width: 100% ;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
}

.eventi{
  margin-top: 10vh;
  margin: 20vh;
}


.titolo_eventi{
  text-align: center;
  color: #BA1852;
  font-size: 5vw;
  font-family: "Lexend Tera" !important;
}

.evento_elem{
  min-height: 100%;
  left: 0;
  border-radius: 10px;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
  font-family: "Lexend Tera" !important;
}

.evento_title{
  text-transform: uppercase;
  text-align: center;
  padding: 20px;
  font-size: 1.5vw;
  font-weight: bold;
  color: #BA1852;
 
}

.evento_descrizione {
  padding: 20px;
}

.evento_data {
  padding: 10px 20px 20px 20px;
  text-align: end;
  color: #BA1852;
  font-size: 1.1vw;
}

/* modifica card  VEDERE CHE DICE LUCA*/ 

/*.contenitore_eventi{
  height: 100%;
}

.foto_eventi{
  width: 100% ;
  height: 30vh;
  border-radius: 10px 10px 0px 0px;
}

.eventi{
  margin-top: 10vh;
  margin: 20vh;
}


.titolo_eventi{
  text-align: center;
  color: #BA1852;
  font-size: 5vw;
  font-family: "Lexend Tera" !important;
}

.evento_elem{
  height: 60vh;
  left: 0;
  border-radius: 10px;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
  font-family: "Lexend Tera" !important;
}

.evento_title{
  text-transform: uppercase;
  text-align: center;
  padding: 20px;
  font-size: 1.5vw;
  font-weight: bold;
  color: #BA1852;
 
}

.evento_descrizione {
  padding: 20px;
  height: 15vh;
}

.evento_data {
  padding: 0px 20px 20px 20px;
  text-align: end;
  color: #BA1852;
  font-size: 1.1vw;
}*/