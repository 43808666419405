.riga_informazioni{
  padding: 30px;
  padding-bottom: 3vh;

}

.operatore {
  border: none;
  border-radius: 50%;
  background-color: #BA1852;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  width: 50px;
  height: 50px;
}

.button_operatore {
  background-color: white;
  border: none;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
}

.tot_adulti {
  text-align: center;
  font-size: 40px;
}

.date_picker {
  border: 0;
  border-bottom: 1px solid black;
  width: 100%;
  padding-top: 10px;
  font-size: 1.3vw;
}

.form-control{
  border: 0;
  border-bottom: 1px solid black;
}

.titolo_input_informaizoni{
  color: #BA1852 !important;
}

.errore_form{
  color: #BA1852;
}

.error_input{
  border: 1px solid #BA1852;
  border-radius: 5px;
}

.date_picker{
  border-radius: 5px;
}

.notificaInviato{
  border: 1px solid green;
  background-color: #D4EDDA;
  color: green;
  padding: 30px;
  width: 30%;
  border-radius: 10px;
  position: fixed;
  top: 10%;
  left: 35%;
  
}