#info {
  text-align: center;
  padding: 10%;
  font-family: 'Lexend Tera',sans-serif;
  font-size: 1.9vw;
  color: #BA1852;
  width: -webkit-fill-available;
  margin-top: 100vh;
  margin-left: 15px;
  margin-right: 15px;
}

#icone_descr{
  text-align: center;
}

#icone_img {
  width: 50%;
}

#icone_img {
  margin: auto;
}

.inside_par{
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-weight: 400;
  font-size: 5vw;
  font-family: "Lexend Tera";
  text-transform: uppercase;
  color: #ba1852;
  opacity: not-inherit;
}

.par_container{
  margin-top: 10%;
}

.btn-outline-danger{
  border: 1px solid black !important;
  /*border-radius: 0 !important;*/
  margin-top: 30px;
  color: #BA1852;
  width: 80%;
  border-radius: 10px;
  font-family: "Lexend Tera";
}

.btn-outline-danger:hover,.btn-outline-danger:hover > .descr_in_btn{
  background-color: #BA1852;
  color: white !important;
}

.btn-lg {
  font-size: 1.2vw !important;
}

.img_left{
  margin-top: 20%;
  max-width: 100%;
  margin-left: 10%;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
}

.img_right{
  align-items: center;
  max-width: 90%;
  margin-top: 20%;
  margin-right: 10%;
  /*margin-left: -5vw;*/
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;

}

.descr{
  text-align: center;
  margin-top: 10%;
  width: 80%;
}

.descr_item{
  margin: 0 auto;
}

.descr_descr{
  font-family: "Lexend Tera";
  font-size: 1vw;
  font-weight: 400px;
}

.descr_divisore {
  width: 20%;
  height: 1px;
  background-color: black;
}

.descr_title {
  font-family: "Lexend Tera";
  text-transform: uppercase;
  color: #ba1852;
  font-size: 2.2vw;
}

.descr_btn{
  width: 70%;
  background-color: white;
  color: #ba1852;
  font-family: "Lexend Tera";
  padding: 5% 7% 5% 7%;
  font-size: 1.5rem;
  margin-top: 10%;
}

.descr_in_btn{
  text-decoration: none;
  color: #BA1852 !important;
}

/*.descr_in_btn:hover{
  text-decoration: none;
  color: white !important;
}
*/

.container{
  width: 100% !important;
}

.par_h {
  height: 25vw;
}

/*@media screen and (min-height: 600px) and (max-height: 719px){
  .par_h {
    height: 300px;
  }
}
@media screen and (min-height: 720px) and (max-height: 899px) {
  .par_h {
    height: 500px;
  }
}
@media screen and (min-height: 900px) and (max-height: 1079px) {
  .par_h {
    height: 600px;
  }
}
@media screen and (min-height: 1080px) and (max-height: 1199px) {
  .par_h {
    height: 600px;
  }
}
@media screen and (min-height: 1200px) and (max-height: 1439px) {
  .par_h {
    height: 600px;
  }
}

@media screen and (min-height: 1440px) {
  h1 {
    color: purple;
  }
}*/