.font {
  font-family: "Lexend Tera" !important;
}

#aggiungi_piatto{
  border: 1px solid #BA1852;
  border-radius: 5px;
  padding: 2vw;
}

#menu_title{
  margin-top: 10vh;
}

.tipo_piatti {
  margin-top: 5vh !important;
  color: #BA1852;
}

.divisore {
  border-bottom: 1px solid black;
  padding: 20px;
}

.btn-lg{
  width: 100%;
}


.titoli{
  color: #BA1852;
  padding-bottom: 2vh;
}

.azioni {
  text-align: center;
}

.delete , .edit{
  width: 50%;
}

.delete:hover , .edit:hover{
  cursor: pointer;
}

.btn-primary{
  background-color:  #BA1852 !important;
  border: 1px solid #BA1852;
}

.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none !important;
}


.modal-dialog {
  width: fit-content !important;
  max-width: 100% !important;
}

.modal-content {
  width: max-content !important;
}

/*SWITCHER */


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #BA1852;
}

input:focus + .slider {
  box-shadow: 0 0 1px #BA1852;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.container_gest_menu{
  width: 70% !important;
  margin: auto;
}

.topBottom{
  width: 50%;
}

.topBottom:hover{
  width: 60%;
  cursor: pointer;
}

.arr_top{
  transform: rotate(270deg);
}
.arr_bottom{
  transform: rotate(90deg);
}
.arrows{
  margin-top: -10px;
  text-align: center !important;
}

.pdfIcon:hover{
  cursor: pointer;
}

.pdfIcon{
  width: 30%; 
  padding-top:30px;
}