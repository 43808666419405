.bg-light {
  background-color: white !important;
  position:fixed;
  width: 100%;
  height: 70px;
  z-index: 2;
  /*opacity: 0;*/
  top: 0;
  /*border-bottom: 1px solid #6f2a3c;*/
}

.logo-navbar{
  width: 60%;
  /*left: 0.65%;*/
  opacity: 1;
  /*position: fixed;*/
  padding-left: 5%;
}



.navbar-brand{
  text-align: left;
}

.nav_el{
  font-family: "Lexend Tera";
  color: #BA1852;
  text-decoration: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    #BA1852,
    #BA1852 50%,
    #1D1D1B 50%);
    background-size: 200% 100%;
    background-position: 100%;
    margin-left: 50px;
}

.dropdown{
  font-family: "Lexend Tera";
  color: #BA1852;
padding-left: 50px;
  text-decoration: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    #BA1852,
    #BA1852 50%,
    #1D1D1B 50%);
    background-size: 200% 100%;
    background-position: 100%;

}

.dropdown:hover {
  text-decoration: none;
  width: 100%;
  /*color: #8254ff !important;*/
  /*transition: all 0.5s cubic-bezier(0.000, 0.000, 0.230, 1);*/
  transition: all 0.6s ease-in-out;
  background-position: 0%;
  cursor: pointer;
}

.el_dropdown{
  margin-left: 0;
  padding: 15px 15px 15px 15px;

  /*text-align: center;*/
}

.menuItems {
  text-decoration: none;
  color: #8f8f8f;
  font-size: 1.2vw;
  font-weight: 400;
  transition: all 0.8s ease-in-out;
  position: relative;
  text-transform: uppercase;
}

.nav_el:hover {
  text-decoration: none;
  width: 100%;
  /*color: #8254ff !important;*/
  /*transition: all 0.5s cubic-bezier(0.000, 0.000, 0.230, 1);*/
  transition: all 0.6s ease-in-out;
  background-position: 0%;
  cursor: pointer;
}

.active {
  background-position: 0%;
}

.hide_nav {
  animation: hide_nav 1s;
  position: fixed;
  animation-fill-mode: forwards;
  /*background-color: red !important;*/
}

.show_nav {
  animation: show_nav 1s;
  position: fixed;
  animation-fill-mode: forwards;
  /*background-color: red !important;*/
}

@keyframes show_nav {

  0%{
    transform:translateY(20%);
  }

  100% {
    transform-origin: 0 0;
    transform:translateY(-80%) ;
  }
}

@keyframes hide_nav {
  0% {
    transform-origin: 0 0;
    transform:translateY(-80%);
  }

  100% {
    transform-origin: 0 0;
    transform:translateY(20%);
  }

}


.opacizza_navbar {
  background-color: white !important;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
    /*opacity: 1;*/
 
}

.disopacizza_navbar {
  background-color: transparent !important;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
}
@supports (-moz-appearance:none) {
  .logo-navbar{
    width: 10vw;
  }
  .bg-light {
    background-color: white !important;
  }

}

/*@media (pointer:coarse) {
  .logo {
    display: none;
  }
  .bg-light{
    opacity:1 !important;
    background-color: white !important;
    height: 100px;
  }
 
  .logo-navbar {
    padding-top: 15px;
    margin-left: 25%;
    display: fixed;
    opacity: 1 !important;
  }
 
  .moveCenter {
   animation: none;
   position: fixed;
   animation-fill-mode: forwards;
 }
 
 .moveUp {
   animation: none;
   position: fixed;
   animation-fill-mode: forwards;
 }
 .hide_nav {
   animation: none;
   position: fixed;
   animation-fill-mode: forwards;
 }
 
 .show_nav {
   animation: none;
   position: fixed;
   animation-fill-mode: forwards;
  }
  .navbar-toggler{
    margin-top: -20%;
    background-color: #BA1852 !important;
  }
  #navbar1 {
    background-color: white;
    margin: 0 auto;
    margin-left: -20px;
    margin-right: -20px;
  }
  .navbar-nav{
    padding-left: 20px;
  }
  .img-landing {
    margin-top: 20%;
  }
 }*/
 @media screen and (max-height: 600px) and (max-height: 719px){

}

/* STESSA ALTEZZA DIVERSA LARGHEZZA NOTEBOOKL*/
@media screen and (min-height: 720px) and (max-height: 899px){

  
}

@media screen and (min-height: 720px) and (max-height: 899px) and (max-width:1150px){

  
}
@media screen and (min-height: 720px) and (max-height: 899px) 
and (min-width:1151px) and (max-width:1300px){

  
}

@media screen and (min-height: 720px) and (max-height: 899px) 
and (min-width:1301px) and (max-width:1366px){

}


/* */

/* altezza 900px pc desktop */

@media screen and (min-height: 900px) and (max-height: 1079px) {

}

@media screen and (min-height: 900px) and (max-height: 1079px) 
and (min-width:1367px) and (max-width:1400px){

}

@media screen and (min-height: 900px) and (max-height: 1079px) 
and (min-width:1401px) and (max-width:1440px){
}

@media screen and (min-height: 900px) and (max-height: 1079px) 
and (min-width:1441px) and (max-width:1680px){

}

/* */


@media screen and (min-height: 1080px) and (max-height: 1200px) {

}



@media screen and (min-height: 1200px) and (max-height: 1439px) {

}

@media screen and (min-height: 1440px) {
}

