.font {
  font-family: "Lexend Tera" !important;
}

.container_gest_drink{
  width: 70% !important;
  margin: auto;
}

#drink_title{
  margin-top: 10vh;
}

.tipo_drink {
  margin-top: 5vh !important;
  color: #BA1852;
}

.divisore {
  border-bottom: 1px solid black;
  padding: 20px;
}

#aggiungi_drink{
  border: 1px solid #BA1852;
  border-radius: 5px;
  padding: 2vw;
}