
.landing-container {
  position: fixed; 
  left: 0; 
  top: 0;
  height:100%;
  min-height:100%;
  z-index: -99;
  text-align: center;
  
}

.img-landing {
  width: 100% !important;
  min-width: 100% ;
  min-height: 100%;
  height: 100% !important;
  position: relative;
  top: 0;
  left: 0;
  object-fit: fill;
  will-change: transform;
  z-index: -1000;
width: 100vw !important;
}

#inizio{
  position: absolute;
  top: 0;
}

.logo {
  position: absolute;
  top: 10%;
  left: 24.9%;
  width: 50vw;
  z-index: 3;
}

.moveCenter {
  animation: toCenter 1s;
  position: absolute;
  animation-fill-mode: forwards;
}

.moveUp {
  animation: toNavbar 1s;
  position: fixed;
  animation-fill-mode: forwards;
}


.slogan {
  position: absolute;
  margin-top: -45vh;
  font-size: 2.5vw;
  color: white;
  width: -webkit-fill-available;
  line-height: 70px;
  letter-spacing: 5px;
  font-style: italic;
  font-family: "Lexend Tera";
  margin-left: 20px;
  margin-right: 20px;
}

#button_tornaSu {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px;
  z-index: 1000000;
  width: 50px;
  height: 50px;
  border: 1px solid #BA1852;
  border-radius: 50%;
  text-align: center;
  transform: rotate(180deg);
  
}

#button_tornaSu:hover {
  background-color: #BA1852;
  cursor: pointer;
  
}

@keyframes toNavbar {

  0%{
    transform-origin: 0 0;
    transform:translateY(22.5%) translateX(0) scale(1, 1);
  }

  100% {
    transform-origin: 0 0;
    transform:translateY(-22.5%) translateX(-48.5%) scale(0.2, 0.2);
  }

}

@keyframes toCenter {
  0% {
    transform-origin: 0 0;
    transform:translateY(-30.5%) translateX(-45%) scale(0.2, 0.2);
  }

  100% {
    transform-origin: 0 0;
    transform:translateY(0) translateX(0) scale(1, 1);
  }

}

.arrow
{
  position: absolute;

  left: 50%;
  margin-left:-20px;
  width: 40px;
  height: 40px;
  margin-top: -10vh;

  /**
   * Dark Arrow Down
   */
  background-image: url(../images/icona_frecce.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}



@media screen and (max-height: 600px){
  .logo {
    top: 10%;
  }
  .slogan {
    
    font-size: 1.9vw;
    line-height: 30px;
    letter-spacing: 5px;
  }

}
@media screen and (min-height: 601px) and (max-height: 719px){
  .logo {
    top: 10%;
  }
  .slogan {
  
    font-size: 1.9vw;
    line-height: 30px;
    letter-spacing: 5px;
  }

}
@media screen and (min-height: 720px) and (max-height: 899px) {
  .logo {
    top: 10%;
  }
  .slogan {
  
    font-size: 1.9vw;
    line-height: 30px;
    letter-spacing: 5px;
  }

}
@media screen and (min-height: 900px) and (max-height: 1079px) {
  .logo {
    top: 15%;
  }
  .slogan {
    
    font-size: 2.2vw;
    line-height: 40px;
    letter-spacing: 5px;
  }

}
@media screen and (min-height: 1080px) and (max-height: 1200px) {

  .slogan {
    
    font-size: 2.3vw;
    line-height: 65px;
    letter-spacing: 5px;
  }

}
@media screen and (min-height: 1200px) and (max-height: 1439px) {
  h1 {
    color: darkgray;
  }
}

@media screen and (min-height: 1440px) {
  h1 {
    color: purple;
  }
}