
.titolo_drink{
  text-align: center;
  color: #BA1852;
  font-size: 5vw;
}

.tipo_drink {
  margin-top: 5vh !important;
  color: #BA1852;
  /*border: 1px solid black;*/
  /*padding: 20px;*/
  min-width: 40%;
  width: fit-content;
  font-weight: bold;
  font-size: 2vw;
  /*text-align: center;*/
}

.nome_drink{
  color: #BA1852;
  text-transform: uppercase;
  padding-top: 2vh;
  font-size: 1.2vw;
}

.prezzo_drink{
  color: #BA1852;
  text-transform: uppercase;
  padding-top: 2vh;
  font-size: 1.2vw;
  text-align: end;
}

.descrizione_drink{
  padding-bottom: 1vw;
}

.separatore_drink{
  background-color: black;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
