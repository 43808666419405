

.titolo_menu{
  text-align: center;
  color: #BA1852;
  font-size: 5vw;
}

.tipo_piatti_menu {
  margin-top: 5vh !important;
  color: #BA1852;
  /*border: 1px solid black;*/
  /*padding: 20px;*/
  min-width: 40%;
  width: fit-content;
  font-weight: bold;
  font-size: 2vw;
  /*text-align: center;*/
}

#hr_sotto_menu{
  width: 10%;
  background-color: black;
  margin-bottom: 10vh;
}
.img_right_menu {
 width: 20vw;
}
.img_left_menu {
  width: 20vw;
 }


 .img_right_menu_cont{
   text-align: end;
 }

 .cont {
   margin: 0;
   overflow: hidden;
   font-family: "Lexend Tera" !important;
 }

 .img_left_menu_cont{
  text-align: start;
}

.nome_piatto_menu{
  color: #BA1852;
  text-transform: uppercase;
  padding-top: 2vh;
  font-size: 1.2vw;
}

.separatore_piatto_menu{
  background-color: black;
  margin-top: 0rem;
  margin-bottom: 0rem;
}


.prezzo_piatto_menu{
  color: #BA1852;
  text-transform: uppercase;
  padding-top: 2vh;
  font-size: 1.2vw;
  text-align: end;
}

.descrizione_piatto_menu{
  padding-bottom: 1vw;
}

