

.titolo_albergo{
  text-align: center;
  color: #BA1852;
  font-size: 5vw;
}

#hr_sotto_albergo{
  width: 10%;
  background-color: black;
  margin-bottom: 10vh;
}

.titolo_desc_albergo {
  margin-top: 10vh !important;
  color: #BA1852;
  /*border: 1px solid black;*/
  /*padding: 20px;*/
  min-width: 50%;
  width: fit-content;
  /*text-align: center;*/
  margin-bottom: 4vh;
  font-weight: bold;
  font-size: 2vw;
}

.servizi_albergo{
  padding-left: 10vw;
}

.titolo_servizi {
  color: #BA1852;
  font-weight: bold;
}

.riga_servizi{
  padding-bottom: 2vh;
}

.img_desc_riga_servizi{
  width: 3vw;
}

.desc_servizi{
  margin-top: 2vh;
}

.info_prenotazioni{
  margin-top: 5vh;
  text-align: center;
}

.img_info_pronotazioni{
  width: 3vw;
}

.servizi_prenotazione{
  color: #BA1852;
  padding-top: 15px;
  font-size: clamp(0.8rem, 1vw, 1.2rem) !important;
}

.descrizione_serviziEprenotazione{
  padding-right: 7vw;
  padding-top: 10vh;
}

.btn_disponibilita{
  background-color: white;
  color: #BA1852;
  padding: 20px;
  width: 70%;
  margin-bottom: 3vh;
  border: 1px solid black;
  border-radius: 10px;
}

.btn_disponibilita:hover{
  background-color: #BA1852;
  color: white;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px 30px;
}

.tabella_prezzi{
  margin-top: 2vh;
  padding-left: 10vw;
  margin-right: 2vw;
}



.titolo_prezzi { 
  color: #BA1852;
  font-weight: bold;
  font-size: 2vw;
}