#footer{
  color: white;
  margin-top: 10%;
  background-color: #ba1852; /*#6F2A3C*/
  bottom:0;
  margin-bottom: -20px;
  font-family: "Lexend Tera";
}

#f_title{
  padding-top: 50px;
  text-align: center;
  font-size: 6vw;
}

.center_elems{
  padding-top: 5%;
  text-align: center;
  padding-bottom: 2%;
  margin-left: 10%;
  margin-right: 10%;
}

.f_col {
  text-align: left;
  font-size: 1vw;
  padding-left: 5%;
}

#icone_social {
  padding-top: 20px;
  padding-bottom: 30px;
}

#contatti_f {
  color: white !important;
}

#burocrazia{
  margin-top: 15vh;
  font-size: 0.5vw;
}

.icone_social{
  width: 50%;
  padding-bottom: 15px;
}

.icone_social:hover{
  cursor: pointer;
}
.privacy{
  text-transform: uppercase;
  color: white;
  margin-bottom: 15px;
}



.iubenda-embed{
  padding-bottom: 30px;
}

a {
  color: #FFFFFF;
  text-decoration: none;
}

a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}