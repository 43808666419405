@media (pointer:coarse) {
  .App{
    overflow: hidden;
  }
  
  .landing-container{
    max-width: 100vw;
    justify-content: center;
  }

  .navbar-brand{
    width: 70%;
    text-align: end;
  }

  .slogan{
    font-size: 2vh;
  }

  .navbar{
    background-color: white !important;
  }

  .navbar-toggler{
    background-color: #BA1852 !important;
  }

  .menuItems{
    background-color: white;
    font-size: 3vh;
    padding-top: 50px;
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 30px;
  }

  .landing-container{
    z-index: 0;
    position: absolute;
  }

  .inside_par{
    font-size: 5vh;
  }

  .logo{
    width: 70vw;
    left:15%
  }

  .contenitore_info{
    background-color: white !important;
    z-index: 1000000;
    height:100%;
    width:100%;
  }

  .par_h{
    height: 50vh;
  }

  #info{
    font-size: 2vh;
    padding: 0;
  }

  #icone_img{
    width: 80%;
    padding-top: 15% !important;
    padding-bottom: 15%;
    padding-top: 4vh;
  }

  .titolo_eventi{
    font-size: 5vh;
  }

  .descr{
    width: 100%;
    font-size: 1vh;
  }

  .img_left{
    margin-left: 0;
  }

  .img_right {
    padding-left: 0;
    margin-left: 0;
    padding-right: 0;
    max-width: 100%;
  }

  .descr_title{
    font-size: 2.5vh;
  }

  .descr_descr {
    font-size: 2vh;
  }

  .btn-lg{
    font-size: 2vh !important;
  }


  /* MENU */

  .img_left_menu_cont{
    display: none;
  }
  .img_right_menu_cont{
    display: none;
  }

  .descr_left_menu{
    flex:100%;
    max-width: 100%;
    padding: 0vw 10vw 5vw 10vw;
  }

  .titolo_menu{
    margin-top: 30px;
    font-size: 5vh;
  }

  #hr_sotto_menu {
    width: 50%;
    margin-bottom: 3vh;
  }

  .tipo_piatti_menu {
    font-size: 3vh;
    border: 0px;
    padding: 0;
    text-align: center;
    width: auto;
    font-weight: bold;
    padding-bottom: 20px;
  }

  .nome_piatto_menu{
    font-size: 2vh;
    font-weight: bold;
  }
  
  .prezzo_piatto_menu{
    font-size: 2vh;
  }

  .descrizione_piatto_menu{
    font-size: 1.7vh;
  }

  /* FOOTER */

  #f_title{
    font-size: 10vw;
  }

  #icone_social{
    max-width: 100%;
  }

  .f_col{
    font-size: 2vh;
  }


/*DESC ALBERO */

.titolo_albergo{
  font-size: 10vw;
}

  .riga_servizi {
    padding: 5px;
  }

  .titolo_desc_albergo{
    border: 0;
  }

  .titolo_servizi{
    font-size: 3.5vw;
  }

  .img_desc_riga_servizi{
    width: auto;
  }
  .desc_servizi{
    font-size: 3vw;
  }

  .titolo_desc_albergo { 
    font-size: 6vw;
    font-weight: bold;
  }

  .btn_disponibilita{
    width: 90%;
  }

  .long {
    text-align: center;
    font-size: 3.5vw;
  }


  .descrizione_serviziEprenotazione{
    padding-right: 30px;
    padding-left: 30px;
   }

   .img_info_pronotazioni{
     width: 20vw;
   }

   .elem_info_prenotazioni{
    margin-bottom: 6vh;
   }

   .modal-dialog{
     max-width: 100%;
   }

   .modal-content {
     max-width: 95vw;
   }

   .item_informazioni{
     margin-bottom: 4.5vh;
   }


   .check{
     margin-top: 10vh;
   }

   .date_picker{
     font-size: 4vh;
   }

   .modal-footer{
     justify-content: center;
   }
   .modal_info_control{
     width: 50vw;
   }


   /*eventi home*/
   .contenitore_eventi{
     margin: 20px;
   }
   .evento_elem{
    max-height: 100%;
   }
   .spacing_evento{
     margin-bottom: 50px;
   }
   .evento_title{
     font-size: 6vw;
   }
   .evento_data{
     font-size: 4vw;
   }


  [data-aos-offset] {
    offset: 0!important;
  }

  .f_col{
    text-align: center;
  }
  .icone_centering{
    justify-content: center;
  }

  #burocrazia {
    padding-top: 5vh;
    margin-top: 0;
    font-size: 2vw;
  }

  .center_elems{
    padding-bottom: 10%;
  }


  /* drink list */

  .titolo_drink{
    margin-top: 30px;
    font-size: 5vh;
  }

  #hr_sotto_menu {
    width: 50%;
    margin-bottom: 3vh;
  }

  .tipo_drink {
    font-size: 3vh;
    border: 0px;
    padding: 0;
    text-align: center;
    width: auto;
    font-weight: bold;
    padding-bottom: 20px;
  }

  .nome_drink{
    font-size: 2vh;
    font-weight: bold;
  }
  
  .prezzo_drink{
    font-size: 2vh;
  }

  .descrizione_drink{
    font-size: 1.7vh;
  }

  .descr_drink{
    flex:100%;
    max-width: 100%;
    padding: 0vw 10vw 5vw 10vw;
  }


}